<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-btn
      to="/pages/login"
      class="ml-2"
      min-width="100"
      text
      @click="logout"
    >
      <v-icon right>
        mdi-account-arrow-right
      </v-icon>
      <span>Log out</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      profile: [
        { title: 'Log out', to: '*' },
      ],
    }),

    computed: {
      ...mapState(['drawer']),

      toDashboard: function () {
        // console.log('store admin', this.$store.getters.isAdmin)
        return this.$store.getters.isAdmin ? '/' : '/user/dashboard'
      },
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      logout: function () {
        this.$store.dispatch('logout').then(() => {
          this.$router.push('/pages/login')
        })
      },
      /* logout () {
        localStorage.clear()
      }, */
    },
  }
</script>
